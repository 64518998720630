import React from 'react';
import * as dto from '@/generated/web_api/dto';
import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

// import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const UserAvatar2: React.FC<{
  className?: string;
  user: dto.UserInfo | null;
}> = ({
  className,
  user
}) => {
  if (!user) return null;
  const getFallbackWord = () => {
    if (user.username) return user.username[0];
    if (user.fullName) return user.fullName[0];
    return '';
  };
  return <Avatar className={className} data-sentry-element="Avatar" data-sentry-component="UserAvatar2" data-sentry-source-file="UserAvatar2.tsx">
      <AvatarImage src={user.avatarUrl ?? undefined} alt={user.fullName ?? undefined} data-sentry-element="AvatarImage" data-sentry-source-file="UserAvatar2.tsx" />
      <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="UserAvatar2.tsx">{getFallbackWord()}</AvatarFallback>
    </Avatar>;
};
export const UserInfo2: React.FC<{
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  profile: dto.UserInfo;
  avatarOnly?: boolean;
}> = ({
  className,
  size = 'md',
  profile,
  avatarOnly
}) => {
  if (!profile) return null;
  return <div className={cn('flex items-center gap-2', size === 'sm' ? 'text-sm gap-1' : size === 'md' ? 'text-base' : 'text-lg', className)} data-uid={profile.id} data-sentry-component="UserInfo2" data-sentry-source-file="UserAvatar2.tsx">
      <UserAvatar2 user={profile} className={cn(size === 'sm' ? 'w-6 h-6' : size === 'md' ? 'w-8 h-8' : 'w-10 h-10')} data-sentry-element="UserAvatar2" data-sentry-source-file="UserAvatar2.tsx" />
      {!avatarOnly && <div>{profile.username || profile.fullName}</div>}
    </div>;
};