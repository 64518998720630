import { User } from '@supabase/supabase-js'

interface MetricsEventData {
  event_name: string
  properties?: Record<string, any>
}

export const recordEvent = async (
  eventData: MetricsEventData,
  user: User | null
) => {
  try {
    const response = await fetch('/api/metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...eventData,
        user_id: user?.id,
      }),
    })

    if (!response.ok) {
      throw new Error('Failed to record event')
    }

    const result = await response.json()
    return result
  } catch (error) {
    console.error('Error recording event:', error)
    return { success: false, message: 'Failed to record event' }
  }
}
