import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import type { Empty } from '@/generated/google/protobuf/empty';
import { goMsgApiService } from '@/generated/user_message/user_message_api.gen.client';
import type { UserMessage as ProtoUserMessage } from '@/generated/web_api/dto';
import { useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';
import { Bell } from 'lucide-react';
import React, { useEffect, useState } from 'react';

// 删除原有的接口定义，使用 proto 生成的类型
type Message = ProtoUserMessage;
export const MessageBox: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [open, setOpen] = useState(false);
  const {
    hasLogin
  } = useUser();
  const fetchMessages = async () => {
    try {
      const empty: Empty = {};
      const response = await goMsgApiService.GetUserAllMessageList(empty);
      if (response.data?.messages) {
        setMessages(response.data.messages);
        const newUnreadCount = response.data.messages.filter((msg: Message) => !msg.isRead).length;
        setUnreadCount(newUnreadCount);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };
  useEffect(() => {
    if (hasLogin) {
      fetchMessages();
    }
  }, [hasLogin]);
  useEffect(() => {
    if (!hasLogin) return;
    const interval = setInterval(() => {
      fetchMessages();
    }, 30000);
    return () => clearInterval(interval);
  }, [hasLogin]);
  useEffect(() => {
    if (!hasLogin) return;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchMessages();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasLogin]);
  const markAsRead = async (id: number) => {
    try {
      await goMsgApiService.ReadUserMessage({
        messageId: id
      });
      setMessages(messages.map(msg => msg.id === id ? {
        ...msg,
        isRead: true
      } : msg));
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Failed to mark message as read:', error);
    }
  };

  // 清空消息的函数
  const clearMessages = () => {
    setMessages([]);
    setUnreadCount(0);
    setExpandedId(null);
  };

  // 监听登录状态变化，退出登录时清空消息
  useEffect(() => {
    if (!hasLogin) {
      clearMessages();
    }
  }, [hasLogin]);
  const handleMessageClick = async (msg: Message) => {
    // 如果已经展开，则收起
    if (expandedId === msg.id) {
      setExpandedId(null);
      return;
    }

    // 展开消息
    setExpandedId(msg.id);

    // 如果消息未读，则标记为已读
    if (!msg.isRead) {
      await markAsRead(msg.id);
    }
  };

  // 格式化日期的辅助函数
  const formatDate = (timestamp?: {
    seconds?: bigint | number;
    nanos?: number;
  }) => {
    try {
      if (!timestamp?.seconds) {
        return '无效日期';
      }
      const milliseconds = Number(timestamp.seconds) * 1000 + (timestamp.nanos ? timestamp.nanos / 1000000 : 0);
      const date = new Date(milliseconds);
      if (isNaN(date.getTime())) {
        return '无效日期';
      }
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Date parsing error:', error);
      return '无效日期';
    }
  };
  return <DropdownMenu open={open} onOpenChange={setOpen} data-sentry-element="DropdownMenu" data-sentry-component="MessageBox" data-sentry-source-file="MessageBox.tsx">
      <DropdownMenuTrigger className={cn('relative', className)} aria-label="消息通知" title="消息通知" data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="MessageBox.tsx">
        <Bell className="w-6 h-6" data-sentry-element="Bell" data-sentry-source-file="MessageBox.tsx" />
        {unreadCount > 0 && <span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-80 max-h-96 overflow-y-auto custom-scrollbar bg-white/90 backdrop-blur-sm" onCloseAutoFocus={e => e.preventDefault()} style={{
      '--scrollbar-color': 'rgba(0, 0, 0, 0.15)',
      '--scrollbar-hover-color': 'rgba(0, 0, 0, 0.25)',
      '--scrollbar-width': '4px'
    } as React.CSSProperties} data-sentry-element="DropdownMenuContent" data-sentry-source-file="MessageBox.tsx">
        {messages.length === 0 ? <div className="p-4 text-center text-gray-500">No messages</div> : messages.map(msg => <DropdownMenuItem key={msg.id} className={cn('flex flex-col items-start p-3 cursor-pointer transition-colors duration-200', !msg.isRead ? 'bg-gray-50/80' : 'hover:bg-gray-50/40')} onClick={e => {
        e.preventDefault();
        handleMessageClick(msg);
      }}>
              <div className="flex items-start w-full">
                {!msg.isRead && <span className="w-2 h-2 bg-red-500 rounded-full mt-1.5 mr-2 flex-shrink-0" />}
                <div className="flex-1 min-w-0">
                  <div className={cn('overflow-hidden transition-all duration-700', expandedId === msg.id ? 'max-h-[500px]' : 'max-h-6')} style={{
              transitionTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
            }}>
                    <p className={cn('transition-all duration-700', expandedId === msg.id ? 'opacity-100 transform-none' : 'opacity-90 line-clamp-1', 'whitespace-pre-line')} style={{
                transitionTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
              }}>
                      {msg.message}
                    </p>
                  </div>
                  <span className="text-xs text-gray-500 mt-2 block">
                    {formatDate(msg.createTime)}
                  </span>
                </div>
              </div>
            </DropdownMenuItem>)}
      </DropdownMenuContent>
    </DropdownMenu>;
};