import { Search, X } from 'lucide-react';
import React, { useState, useEffect, useCallback } from 'react';
import { TabType } from './useWebsiteList';
interface WebsitesTabsProps {
  className?: string;
  tabs: readonly TabType[];
  initialActiveTab: TabType;
  initialSearchQuery?: string;
  onStateChange: (state: {
    activeTab: TabType;
    searchQuery: string;
  }) => void;
}
export const WebsitesTabs: React.FC<WebsitesTabsProps> = ({
  className,
  tabs,
  initialActiveTab,
  initialSearchQuery = '',
  onStateChange
}) => {
  const [activeTab, setActiveTab] = useState<TabType>(initialActiveTab);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [isSearching, setIsSearching] = useState(false);
  const notifyStateChange = useCallback(() => {
    onStateChange({
      activeTab,
      searchQuery
    });
  }, [activeTab, searchQuery, onStateChange]);
  useEffect(() => {
    notifyStateChange();
  }, [activeTab, searchQuery, notifyStateChange]);
  const handleTabClick = (tab: TabType) => {
    setActiveTab(tab);
    if (searchQuery) {
      setSearchQuery('');
      setIsSearching(false);
    }
  };
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
    if (isSearching) {
      setSearchQuery('');
    }
  };
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchQuery(searchQuery.trim());
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchQuery(searchQuery.trim());
    }
  };
  return <div className={`flex items-center space-x-2 ${className}`} data-sentry-component="WebsitesTabs" data-sentry-source-file="website-tabs.tsx">
      {tabs.map(tab => <button key={tab} onClick={() => handleTabClick(tab)} className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${activeTab === tab ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}>
          {tab}
        </button>)}
      <div className="flex-grow"></div>
      {/* Search input */}
      {isSearching ? <form onSubmit={handleSearchSubmit} className="flex items-center">
          <div className="relative">
            <input type="text" value={searchQuery} onChange={handleSearchInputChange} onKeyPress={handleKeyPress} className="pl-10 pr-4 py-2 w-64 rounded-full bg-gray-100 border-2 border-gray-300 focus:outline-none focus:border-gray-500 text-sm" placeholder="Search..." autoFocus />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          </div>
          <button type="button" onClick={() => setSearchQuery('')} className="p-2 ml-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200">
            <X className="h-5 w-5 text-gray-600" />
          </button>
        </form> : <button onClick={handleSearchClick} className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200">
          <Search className="h-5 w-5 text-gray-600" />
        </button>}
    </div>;
};