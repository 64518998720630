'use client';

import { useRouter } from 'next/navigation';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useUser } from '@/hooks/useUser';
import { APIClient } from './client';
export const APIContext = createContext<APIClient | null>(null);
export const APIProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const apiClient = useMemo(() => new APIClient(router), [router]);
  return <APIContext.Provider value={apiClient} data-sentry-element="unknown" data-sentry-component="APIProvider" data-sentry-source-file="provider.tsx">{children}</APIContext.Provider>;
};
export const useAPI = () => {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
export const ArmsRumProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const {
    user
  } = useUser();
  useEffect(() => {
    const initRum = async () => {
      if (!process.env.NEXT_PUBLIC_ARMS_PID || !process.env.NEXT_PUBLIC_ARMS_ENDPOINT) {
        console.error('NEXT_PUBLIC_ARMS_PID or NEXT_PUBLIC_ARMS_ENDPOINT is not set');
        return;
      }
      const ArmsRum = (await import('@arms/rum-browser')).default;
      ArmsRum.init({
        pid: process.env.NEXT_PUBLIC_ARMS_PID,
        endpoint: process.env.NEXT_PUBLIC_ARMS_ENDPOINT,
        user: {
          name: user && user.id ? user.id.toString() : 'Guest'
        }
      });
    };
    initRum();
  }, [user]);
  return <>{children}</>;
};