import { useState, useEffect, useCallback, useMemo } from 'react'

import * as dto from '@/generated/web_api/dto'
import * as websiteService from '@/generated/web_api/website_service'
import { goWebsiteService } from '@/generated/web_api/website_service.gen.client'
import { useAPI } from '@/utils/api/provider'

export type TabType = 'Featured' | 'Latest' | 'Mine'

const tabToListType: Record<TabType, websiteService.WebsiteListType> = {
  Featured: websiteService.WebsiteListType.FEATURED,
  Latest: websiteService.WebsiteListType.LATEST,
  Mine: websiteService.WebsiteListType.MINE,
}

interface QueryParams {
  tab: TabType
  search: string
}

interface WebsiteListState {
  websites: dto.Website[]
  isLoading: boolean
  error: string | null
  hasMore: boolean
}

const INITIAL_STATE: WebsiteListState = {
  websites: [],
  isLoading: false,
  error: null,
  hasMore: true,
}

export function useWebsiteList() {
  const [state, setState] = useState<WebsiteListState>(INITIAL_STATE)
  const [queryParams, setQueryParams] = useState<QueryParams>({
    tab: 'Featured',
    search: '',
  })
  const [page, setPage] = useState(1)
  const api = useAPI()

  const loadWebsites = useCallback(async () => {
    if (state.isLoading || !state.hasMore) return

    setState((prev) => ({ ...prev, isLoading: true, error: null }))

    try {
      const data = await goWebsiteService.GetWebsiteList({
        websiteIds: [],
        keyword: queryParams.search,
        listType: tabToListType[queryParams.tab],
        page: {
          pageNo: page,
          pageSize: 12,
        },
      })

      setState((prev) => ({
        websites:
          page === 1
            ? data.data?.websites || []
            : [...prev.websites, ...(data.data?.websites || [])],
        isLoading: false,
        error: null,
        hasMore:
          data.data?.websites?.length === 12 && data.data?.websites?.length > 0,
      }))

      setPage((prev) => prev + 1)
    } catch (err) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        error: 'Failed to load websites. Please try again later.',
      }))
      console.error('Error fetching websites:', err)
    }
  }, [api, queryParams, page, state.isLoading, state.hasMore])

  const updateQueryParams = useCallback((newParams: Partial<QueryParams>) => {
    setQueryParams((prev) => ({ ...prev, ...newParams }))
    setState(INITIAL_STATE)
    setPage(1)
  }, [])

  useEffect(() => {
    if (page === 1) {
      loadWebsites()
    }
  }, [page, loadWebsites])

  const loadNextPage = useCallback(() => {
    if (!state.isLoading && state.hasMore) {
      loadWebsites()
    }
  }, [loadWebsites, state.isLoading, state.hasMore])

  return useMemo(
    () => ({
      ...state,
      loadNextPage,
      updateQueryParams,
    }),
    [state, loadNextPage, updateQueryParams]
  )
}
