import React, { useState } from 'react';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
export const FloatingCard: React.FC<{
  className?: string;
  children: React.ReactNode | ((status: {
    isHovered: boolean;
  }) => React.ReactNode);
  onClick?: () => void;
}> = ({
  className,
  children,
  onClick
}) => {
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const card = e.currentTarget;
    const {
      left,
      top,
      width,
      height
    } = card.getBoundingClientRect();
    const centerX = left + width / 2;
    const centerY = top + height / 2;
    const rotateY = (e.clientX - centerX) / width * 10;
    const rotateX = -((e.clientY - centerY) / height) * 10;
    setRotateX(rotateX);
    setRotateY(rotateY);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setRotateX(0);
    setRotateY(0);
    setIsHovered(false);
  };
  return <Card className={cn('shadow-md relative overflow-hidden', 'hover:shadow-lg transition-shadow duration-300', className)} onClick={onClick} onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{
    transform: `8
          perspective(1000px) 
          rotateX(${rotateX}deg) 
          rotateY(${rotateY}deg)
          ${isHovered ? 'translateY(-2px) scale(1.04)' : ''}
        `,
    transition: 'all 0.2s ease-out'
  }} data-sentry-element="Card" data-sentry-component="FloatingCard" data-sentry-source-file="index.tsx">
      {typeof children === 'function' ? children({
      isHovered
    }) : children}
    </Card>;
};