import { FloatingCard } from '@/components/FloatingCard';
import { Skeleton } from '@/components/ui/skeleton';
export const WebsiteCardSkeleton = () => <FloatingCard className="w-full overflow-hidden transition-all duration-300 rounded-2xl" data-sentry-element="FloatingCard" data-sentry-component="WebsiteCardSkeleton" data-sentry-source-file="website-list-skeleton.tsx">
    <div className="flex flex-col">
      <Skeleton className="aspect-[16/9] w-full rounded-t-2xl" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
      <div className="p-4 bg-white rounded-b-2xl">
        <div className="flex gap-2 mb-3">
          <Skeleton className="h-4 w-16 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
          <Skeleton className="h-4 w-16 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
        </div>
        <Skeleton className="h-5 w-full mb-2" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
        <Skeleton className="h-5 w-3/4" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center space-x-2">
            <Skeleton className="h-7 w-7 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
            <Skeleton className="h-5 w-24 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
          </div>
          <div className="flex items-center space-x-4">
            <Skeleton className="h-5 w-12 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
            <Skeleton className="h-5 w-12 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="website-list-skeleton.tsx" />
          </div>
        </div>
      </div>
    </div>
  </FloatingCard>;
export const WebsiteListSkeleton = () => <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 max-w-screen-2xl mx-auto" data-sentry-component="WebsiteListSkeleton" data-sentry-source-file="website-list-skeleton.tsx">
    {[...Array(8)].map((_, index) => <WebsiteCardSkeleton key={index} />)}
  </div>;