import React, { useState, useEffect } from 'react';
import SingleLightPointerIcon from '@/assets/icons/single-pointer.svg';
import { cn } from '@/lib/utils';
const SingleLightPointer: React.FC<{
  className?: string;
  opacity: number;
  color: string;
  style?: React.CSSProperties;
}> = ({
  className,
  opacity,
  color,
  style
}) => {
  return <SingleLightPointerIcon className={cn(className)} style={{
    opacity,
    color,
    ...style
  }} data-sentry-element="SingleLightPointerIcon" data-sentry-component="SingleLightPointer" data-sentry-source-file="index.tsx" />;
};
export const LightPointer: React.FC<{
  className?: string;
  color?: string;
  duration?: number;
}> = ({
  className,
  color = "grey",
  duration = 400
}) => {
  const [activeIndex, setActiveIndex] = useState(2);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % 3);
    }, duration); // 每隔 1 秒切换一次灯光
    return () => clearInterval(interval);
  }, []);
  const getOpacity = (index: number) => {
    if (index === activeIndex) return 1;
    if ((index + 1) % 3 === activeIndex) return 0.5;
    return 0.1;
  };
  return <div className={cn("flex items-center justify-center", className)} data-sentry-component="LightPointer" data-sentry-source-file="index.tsx">
      <SingleLightPointer opacity={getOpacity(0)} color={color} className="transition-all ease-linear absolute" style={{
      transitionDuration: `${duration}ms`,
      transform: 'translateY(-12px)'
    }} data-sentry-element="SingleLightPointer" data-sentry-source-file="index.tsx" />
      <SingleLightPointer opacity={getOpacity(1)} color={color} className="transition-all ease-linear" style={{
      transitionDuration: `${duration}ms`
    }} data-sentry-element="SingleLightPointer" data-sentry-source-file="index.tsx" />
      <SingleLightPointer opacity={getOpacity(2)} color={color} className="transition-all ease-linear absolute" style={{
      transitionDuration: `${duration}ms`,
      transform: 'translateY(12px)'
    }} data-sentry-element="SingleLightPointer" data-sentry-source-file="index.tsx" />
    </div>;
};