import { useCallback, useState } from 'react'

import { UnauthorizedError } from '@/utils/api/client'
import { useAPI } from '@/utils/api/provider'

export const useWebsiteAPI = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const api = useAPI()

  const generate = useCallback(
    async (prompt: string, image?: string | null) => {
      setLoading(true)
      setError(null)

      try {
        const data = await api.createWebsite(prompt, image)
        return data
      } catch (err) {
        // console.error('Error generating website:', err);
        if (err instanceof UnauthorizedError) {
          console.error('UnauthorizedError', err)
          throw err
        }
        setError('Failed to generate website. Please try again.')
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const edit = useCallback(
    async (versionId: string, prompt: string, image?: string | null) => {
      setLoading(true)
      setError(null)

      try {
        const data = await api.editWebsite(versionId, prompt, image)
        return data
      } catch (err) {
        // console.error('Error editing website:', err);
        if (err instanceof UnauthorizedError) {
          console.error('UnauthorizedError', err)
          throw err
        }
        setError('Failed to edit website. Please try again.')
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    generate,
    edit,
    loading,
    error,
  }
}
