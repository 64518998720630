import { ImageIcon, Loader2Icon, XIcon } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';
interface CreatePromptBarProps {
  className?: string;
  prompt: string;
  setPrompt: (prompt: string) => void;
  onGenerate: (prompt: string, image: string | null) => void;
  loading: boolean;
}
export const CreatePromptBar: React.FC<CreatePromptBarProps> = ({
  className,
  prompt,
  setPrompt,
  onGenerate,
  loading
}) => {
  const [images, setImages] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleCreate = useCallback(() => {
    if (loading) {
      return;
    }
    if (!prompt.trim()) {
      toast.error('Please enter what you want to create');
      return;
    }
    onGenerate(prompt, images[0]);
  }, [loading, onGenerate, prompt, images]);
  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !loading && prompt.trim()) {
      handleCreate();
    }
  }, [handleCreate, loading, prompt]);
  const handleImageUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await fetch('/api/images/upload', {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Upload failed');
        }
        const data = await response.json();
        setImages(prevImages => [...prevImages, data.imageUrl]);
        toast.success('Image uploaded successfully');
      } catch (error) {
        console.error('Image upload failed:', error);
        toast.error('Image upload failed');
      } finally {
        setUploading(false);
      }
    }
  }, []);
  const removeImage = useCallback((index: number) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  }, []);
  const triggerFileInput = useCallback(() => {
    console.log('triggerFileInput');
    console.log(fileInputRef.current);
    fileInputRef.current?.click();
  }, []);
  const handlePaste = useCallback(async (e: ClipboardEvent) => {
    const items = e.clipboardData?.items;
    if (!items) return;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        e.preventDefault();
        const blob = items[i].getAsFile();
        if (blob) {
          setUploading(true);
          const formData = new FormData();
          formData.append('file', blob, 'pasted_image.png');
          try {
            const response = await fetch('/api/images/upload', {
              method: 'POST',
              body: formData
            });
            if (!response.ok) {
              throw new Error('Upload failed');
            }
            const data = await response.json();
            setImages(prevImages => [...prevImages, data.imageUrl]);
            toast.success('Image pasted and uploaded successfully');
          } catch (error) {
            console.error('Image upload failed:', error);
            toast.error('Image upload failed');
          } finally {
            setUploading(false);
          }
        }
        break;
      }
    }
  }, []);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('paste', handlePaste);
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);
  return <div className={cn('flex flex-col', className)} data-sentry-component="CreatePromptBar" data-sentry-source-file="CreatePromptBar.tsx">
      <div className="flex items-center flex-col sm:flex-row gap-4 sm:gap-2">
        <div className="flex flex-grow relative h-12 w-full">
          <div className={cn('flex-1 h-12 px-1 relative', 'rounded-3xl shadow border transition-all', 'border-white bg-neutral-100 backdrop-blur-lg', 'justify-start items-center inline-flex gap-1', 'hover:shadow-lg focus-within:shadow-lg')}>
            <input type="text" value={prompt} onChange={e => setPrompt(e.target.value)} onKeyPress={handleKeyPress} placeholder="What would you like to build with CreateAny.ai today?" className={cn("w-full pl-5 text-[#212121] text-md font-normal font-['Inter'] leading-tight", 'bg-transparent', 'focus:outline-none')} />
            <div className="flex items-center gap-2 pr-2">
              {images.length > 0 ? <div className="relative h-8 w-8">
                  <img src={images[0]} alt="Uploaded image" className="h-full w-full object-cover rounded" />
                  <button onClick={() => removeImage(0)} className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-0.5">
                    <XIcon className="h-3 w-3" />
                  </button>
                </div> : <button onClick={triggerFileInput} className={cn('w-9 h-9 bg-[#e6e6e6] rounded-full justify-center items-center inline-flex', 'hover:bg-[#d6d6d6]', 'disabled:bg-[#e6e6e6]')} disabled={uploading}>
                  {uploading ? <Loader2Icon className="h-5 w-5 animate-spin text-black" /> : <ImageIcon className="h-5 w-5 text-black" />}
                </button>}
              <input type="file" ref={fileInputRef} onChange={handleImageUpload} accept="image/*" className="hidden" />
            </div>
          </div>
        </div>
        <Button onClick={handleCreate} className={cn('rounded-full transition-all duration-200 w-full sm:w-24 h-12 shadow', 'disabled:(bg-black bg-opacity-50 text-white cursor-not-allowed)', 'hover:shadow-lg focus:shadow-xl')} disabled={loading || !prompt.trim()} data-sentry-element="Button" data-sentry-source-file="CreatePromptBar.tsx">
          {loading ? <Loader2Icon className="h-4 w-4 animate-spin" /> : <span className="text-sm font-medium">Submit</span>}
        </Button>
      </div>
    </div>;
};