'use client';

import React from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { PUBLIC_LIVECHAT_LICENSE, PUBLIC_SHOW_USER_SUPPORT } from '@/utils/constants';
export const UserSupport = () => {
  function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    console.log('LiveChatWidget.onNewEvent', event);
  }
  if (!PUBLIC_SHOW_USER_SUPPORT || !PUBLIC_LIVECHAT_LICENSE) {
    return null;
  }
  return <LiveChatWidget onNewEvent={handleNewEvent} license={PUBLIC_LIVECHAT_LICENSE} data-sentry-element="LiveChatWidget" data-sentry-component="UserSupport" data-sentry-source-file="index.tsx" />;
};