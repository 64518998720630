import { useCallback } from 'react'

import { recordEvent as recordEventUtil } from '../utils/metrics'

import { useUser } from './useUser'

export const useMetrics = () => {
  const { user } = useUser()

  const recordEvent = useCallback(
    (eventName: string, properties?: Record<string, any>) =>
      recordEventUtil({ event_name: eventName, properties }, user ?? null),
    [user]
  )

  return { recordEvent }
}
