'use client'

import {
  InfinitedResponse,
  Website,
  WebsiteVersion,
  WebsiteWithVersion,
  WebsiteSchema,
  Profile
} from '@repo/common/entities'
import axios, { AxiosInstance, AxiosError } from 'axios'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

import { PUBLIC_SITE_URL } from '../constants'
import { createClient } from '../supabase/client'

const supabase = createClient()
export class UnauthorizedError extends Error {
  constructor(public resErr: AxiosError) {
    super(resErr.message)
    this.name = 'UnauthorizedError'
  }
}

// Add this new custom error class
export class BackendApiError extends Error {
  constructor(
    public code: number,
    public message: string,
    public url: string
  ) {
    super(message)
    this.name = 'BackendApiError'
  }
}

export class APIClient {
  private client: AxiosInstance
  private davinciClient: AxiosInstance
  private backendClient: AxiosInstance

  constructor(private router: AppRouterInstance) {
    this.client = axios.create({
      baseURL: PUBLIC_SITE_URL,
      timeout: 120 * 1000
    })

    this.davinciClient = axios.create({
      baseURL: process.env.DAVINCI_API_URL,
      timeout: 120 * 1000
    })

    this.backendClient = axios.create({
      baseURL: process.env.NEXT_PUBLIC_BACKEND_API_URL + '/api',
      timeout: 120 * 1000
    })

    this.setupInterceptors()
  }

  private setupInterceptors() {
    this.client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          console.log('Unauthorized, redirect to login')
          this.redirectToLogin()
          return Promise.reject(new UnauthorizedError(error))
        }
        return Promise.reject(error)
      }
    )

    this.backendClient.interceptors.response.use(
      (response) => {
        if (response.data.status.code === 401) {
          console.log('Unauthorized, redirect to login')
          this.redirectToLogin()
        }
        return response
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          this.redirectToLogin()
        }
        return Promise.reject(error)
      }
    )

    this.backendClient.interceptors.request.use(async (config) => {
      const {
        data: { session }
      } = await supabase.auth.getSession()

      if (session?.access_token) {
        config.headers.Authorization = `Bearer ${session.access_token}`
      }

      return config
    })
  }

  private redirectToLogin() {
    const currentHref = window.location.href
    const currentUrl = new URL(currentHref)
    const nextUrl = new URL('/login', PUBLIC_SITE_URL)
    nextUrl.searchParams.set('next', currentUrl.toString())
    // redirect to login page
    // window.location.href = nextUrl.toString();
    this.router.push(nextUrl.toString())
  }

  async get<T>(url: string, params?: any): Promise<T> {
    const response = await this.client.get<T>(url, { params })
    return response.data
  }

  async post<T>(url: string, data?: any): Promise<T> {
    const response = await this.client.post<T>(url, data)
    return response.data
  }

  async createWebsite(prompt: string, image?: string | null) {
    const response = await this.client.post<{ id: string }>('/api/websites/create', {
      prompt,
      image
    })
    return response.data
  }

  async editWebsite(versionId: string, prompt: string, image?: string | null) {
    return this.post<{ id: string }>(`/api/versions/${versionId}/edit`, {
      prompt,
      image
    })
  }

  async getLatestWebsites({
    cursor,
    featured,
    userId
  }: {
    cursor?: string
    featured?: boolean
    userId?: string
  }) {
    return this.get<InfinitedResponse<typeof WebsiteSchema>>('/api/websites/latest', {
      cursor,
      featured,
      userId
    })
  }

  async getMineWebsites({ cursor }: { cursor?: string }) {
    return this.get<InfinitedResponse<typeof WebsiteSchema>>('/api/websites/mine', { cursor })
  }

  async getRecommendedWebsites({ did }: { did?: string }) {
    return this.get<Website[]>('/api/websites/recommend', { did })
  }

  async getWebsite(wid: string) {
    return this.get<WebsiteWithVersion>(`/api/websites/${wid}`)
  }

  async getVersion(vid: string) {
    return this.get<WebsiteVersion>(`/api/versions/${vid}`)
  }

  async revokeVersion(vid: string) {
    return this.post<{ id: string }>(`/api/versions/${vid}/revoke`)
  }

  async getUser(uid: string) {
    return this.get<Profile>(`/api/users/${uid}`)
  }

  // Add a method to use the backendClient
  async backendGet<T>(url: string, params?: any): Promise<T> {
    const response = await this.backendClient.get<T>(url, { params })
    return response.data
  }

  async backendPost<T>(url: string, data?: any): Promise<T> {
    const response = await this.backendClient.post<T>(url, data)
    return response.data
  }

  async postGoData<REQ, DATA>(url: string, params?: REQ): Promise<DATA> {
    try {
      const response = await this.backendClient.post<{
        status: { message: string; code: number }
        data: DATA
      }>(url, { ...params })

      if (response.data.status.code !== 0) {
        if (response.data.status.code === 401) {
          this.redirectToLogin()
        }
        throw new BackendApiError(response.data.status.code, response.data.status.message, url)
      }

      return response.data.data
    } catch (error) {
      if (error instanceof BackendApiError) {
        throw error // Re-throw BackendApiError as is
      } else if (error instanceof AxiosError) {
        throw new BackendApiError(error.response?.status || 500, error.message, url)
      } else {
        throw new BackendApiError(
          500,
          'An unexpected error occurred during the backend request',
          url
        )
      }
    }
  }
}
