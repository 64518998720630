import React from 'react';
import { cn } from '@/lib/utils';
const randomBetween = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};
export const ColorBlock: React.FC<{
  className?: string;
  initOpacity?: number;
  color?: string;
  style?: React.CSSProperties;
  enableFloatAnim?: boolean;
  enableShineAnim?: boolean;
  minDurationMs?: number;
  maxDurationMs?: number;
}> = ({
  className,
  style,
  color,
  initOpacity = 1,
  enableFloatAnim,
  enableShineAnim,
  minDurationMs = 1000,
  maxDurationMs = 5000
}) => {
  const [scale, setScale] = React.useState(1);
  const [hueRotate, setHueRotate] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0
  });
  React.useEffect(() => {
    const MIN_SCALE = 0.8;
    const MAX_SCALE = 1.2;
    const MIN_DEGREE = -60;
    const MAX_DEGREE = 60;
    const POSITION_RANGE = 200; // 像素

    const animate = () => {
      const newDuration = randomBetween(minDurationMs, maxDurationMs);
      setDuration(newDuration);
      setTimeout(animate, newDuration);
      if (enableFloatAnim) {
        const targetScale = randomBetween(MIN_SCALE, MAX_SCALE);
        setScale(targetScale);
        setPosition({
          x: randomBetween(-POSITION_RANGE, POSITION_RANGE),
          y: randomBetween(-POSITION_RANGE, POSITION_RANGE)
        });
      }
      ;
      if (enableShineAnim) {
        const targetHue = randomBetween(MIN_DEGREE, MAX_DEGREE);
        setHueRotate(targetHue);
      }
    };
    animate();
  }, [enableFloatAnim, enableShineAnim]);
  return <div className={cn("transition-all", className)} style={{
    opacity: initOpacity,
    background: color,
    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
    filter: `hue-rotate(${hueRotate}deg)`,
    transition: 'all ease-in-out',
    transitionDuration: `${duration}ms`,
    ...style
  }} data-sentry-component="ColorBlock" data-sentry-source-file="index.tsx" />;
};
export const Background: React.FC = () => {
  return <div className="absolute inset-0 z-[-1] w-full top-0 overflow-hidden" data-sentry-component="Background" data-sentry-source-file="index.tsx">
      {/* 最底层渐变 */}
      <ColorBlock className="absolute top-[5rem] min-h-[100vh] h-[100vh] w-full" initOpacity={0.6} color="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(211, 204, 249, 0.40) 26.99%, rgba(188, 162, 235, 0.52) 41.41%, rgba(172, 133, 225, 0.49) 51.19%, rgba(123, 110, 219, 0.47) 64.51%)" enableShineAnim minDurationMs={5000} maxDurationMs={10000} data-sentry-element="ColorBlock" data-sentry-source-file="index.tsx" />

      {/* 星光1 */}
      <ColorBlock className="absolute rounded h-[100vw] w-[100vw] top-[-50vw] left-[5vw]" initOpacity={0.6} color="radial-gradient(29.96% 29.96% at 58.98% 43.09%, #F7AEAF 0%, rgba(255, 255, 255, 0.00) 100%)" enableFloatAnim enableShineAnim data-sentry-element="ColorBlock" data-sentry-source-file="index.tsx" />

      {/* 星光2 */}
      <ColorBlock className="absolute rounded h-[80vw] w-[80vw] top-[-30vw] left-[50vw]" initOpacity={0.6} color="radial-gradient(52.11% 52.11% at 65.47% 26.65%, #F68A5B 0%, rgba(250, 244, 222, 0.00) 100%)" enableFloatAnim enableShineAnim data-sentry-element="ColorBlock" data-sentry-source-file="index.tsx" />

      {/* 圆环 */}
      <div className="absolute w-full top-[calc(80vh)] flex items-center justify-center overflow-y-visible py-4">
        <div className="rounded h-[100vh] min-w-[180vw] filter dark:invert" style={{
        opacity: 1,
        background: "radial-gradient(51.38% 43% at 50% 50%, #FFF 82.2%, rgba(211, 188, 255, 0.08) 100%)"
      }} />
      </div>

      {/* 最下方渐变 */}
      <div className="absolute w-full h-[calc(100%-100vh+10rem-50vh)] top-[calc(150vh-10rem)] flex items-center justify-center visiable py-4">
        <div className="rounded h-full min-w-[160vw]" style={{
        opacity: 0.4,
        background: "linear-gradient(180deg, #FFF 0%, rgba(193, 183, 209, 0.85) 57.83%, #FFF 100%)"
      }} />
      </div>
    </div>;
};